import './scripts/common';
import {onFind} from "@elements/init-modules-in-scope";

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};

import Tooltip from 'bootstrap/js/dist/tooltip';
onFind('[data-bs-toggle="tooltip"]',function (element) {
    new Tooltip(element);
});

import * as lightbox from '@elements/lightbox';
lightbox.init({
    plugins: ['video']
});

import * as lightboxExtend from './scripts/lightbox-extend';
lightboxExtend.init();

import * as scrollSlider from './libs/scroll-slider/index';
scrollSlider.init({
    arrowIconClass: 'icon icon-arrow-right scrolling-area__arrow-icon--position'
});

import Dropdown from 'bootstrap/js/dist/dropdown';
onFind('[data-bs-toggle="dropdown"]',function (element) {
    new Dropdown(element);
});

// import * as readmore from './scripts/readmore';
// readmore.init();

import * as youtubeinline from './scripts/youtubeinline';
youtubeinline.init();

import * as imagehotspot from './scripts/imagehotspot';
imagehotspot.init();

import * as lazyvideo from './scripts/lazyvideo';
lazyvideo.init();

import * as scrollSliderPosition from './scripts/scroll-slider-position';
scrollSliderPosition.init();

import * as lightboxVideos from './scripts/lightbox-videos';
lightboxVideos.init();

import * as copytoClipboard from './scripts/copy-to-clipboard';
copytoClipboard.init();

import * as ajaxForm from '@elements/ajax-form';
ajaxForm.init();

import * as ajaxFormExtend from './scripts/ajax-form-extend'
ajaxFormExtend.init();

import * as hotspot from './scripts/hotspot';
hotspot.init();

import * as scrollVideo from './scripts/scroll-video';
scrollVideo.init();

import * as popover from './scripts/popover';
popover.init();