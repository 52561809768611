import {onFind} from "@elements/init-modules-in-scope";
import {find, trigger, findIn, on, findAllIn, setAttribute} from "@elements/dom-utils";
import {trackVideoProgress} from "./video";

export function init() {
    let initialOpen = false;
    onFind('.js-lightbox--videos', function (lightbox) {
        let defaultUrl = window.location.pathname;
        let currentOpen;

        let instance;
        on('lgInit', (evt) => {
            instance = evt.detail.instance;
        }, lightbox);

        on('lgAfterSlide', (evt) => {
            const { index } = evt.detail;

            let items = findAllIn('.js-lightbox__item', lightbox),
                itemUrl = items[index].getAttribute('data-video-url');

            if (currentOpen) {
                initialOpen = false;

                defaultUrl = window.location.pathname.replace(decodeURIComponent(currentOpen), '');
            }

            if (history.replaceState && !initialOpen) {
                history.replaceState(
                    null,
                    '',
                    defaultUrl + itemUrl,
                );
            }

            currentOpen = itemUrl;
        }, lightbox)

        on('lgAfterClose', (a) => {
            defaultUrl = window.location.pathname.replace(decodeURIComponent(currentOpen), '');

            initialOpen = false;

            if (history.replaceState) {
                history.replaceState(
                    null,
                    document.title,
                    defaultUrl,
                );
            }
        }, lightbox);
    });

    on('load', () => {
        let initialOpenItem = find('[data-lightbox-open-initial]');

        if (initialOpenItem) {
            initialOpen = true;
            trigger('click', initialOpenItem);
        }
    }, window);
}