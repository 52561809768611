import {onFind} from "@elements/init-modules-in-scope";
import {findIn, on} from "@elements/dom-utils";
import {trackVideoProgress} from "./video";

export function init() {
    onFind('.js-lightbox', function (lightbox) {
        let instance;
        on('lgInit', (evt) => {
            instance = evt.detail.instance;
        }, lightbox);

        on('lgAfterSlide', (evt) => {
            let video = findIn('.lg-video-object', instance.$content.selector),
                title = instance.galleryItems[instance.index ?? 0]?.subHtml ?? 'lightbox';

            if (video) {
                on('playing', function () {
                    window.dataLayer.push({
                        "event": "video",
                        "type": "play",
                        "name": title
                    });

                    setTimeout(() => {
                        trackVideoProgress(video, 25, title);
                        trackVideoProgress(video, 50, title);
                        trackVideoProgress(video, 75, title);
                        trackVideoProgress(video, 90, title);
                    }, 100);
                }, video);

                on('pause', function (evt) {
                    // Prevent pause event from firing when video ends
                    if (video.currentTime === video.duration) {
                        return
                    }

                    if (video.getAttribute('data-autoplay') !== 'true') {
                        window.dataLayer.push({
                            "event": "video",
                            "type": "pause",
                            "name": title
                        });
                    }
                }, video);

                on('ended', function () {
                    if (video.getAttribute('data-autoplay') !== 'true') {
                        window.dataLayer.push({
                            "event": "video",
                            "type": "ended",
                            "name": title
                        });
                    }
                }, video);
            }
        }, lightbox)
    });
}